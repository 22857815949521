import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getReportV1(searchData) {
    searchData = Object.fromEntries(
        Object.entries(searchData).filter(([key, value]) => value !== null && value !== '' && key && value !== [])
    );
    return await axios.post(
        `${API_URL}/v1/order/report_v1`,
        searchData,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}


export async function getOrdersCalculated(searchData, page, size) {
    searchData = Object.fromEntries(
        Object.entries(searchData).filter(([key, value]) => value !== null && value !== '' && key && value !== [])
    );
    searchData.page = page
    searchData.size = size
    return await axios.post(
        `${API_URL}/v1/order/list/calculated`,
        searchData,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}