import { reactive } from 'vue'
import {getUser} from "@/api/user";
import {auth} from "@/data/auth"
import {getCompanies} from "@/api/company";

export const user = reactive({
    name: '',
    user_id: '',
    active_company: '',

    async getUserBase(){
        await this.getUserData()
        return {
            name: this.name,
            user_id: this.user_id
        }
    },

    async getUserData(){
        if(!this.user_id){
            this.user_id = localStorage.getItem('user_id')
        }
        if(!this.name){
            const user_data = await getUser(this.user_id)
            this.name = user_data.data.name
        }
        if(!this.active_company){
            const user_companies = await getCompanies()
            if(user_companies['data'].length){
                this.active_company = user_companies['data'][0].id
            }
        }
    },



    async getActiveCompany(){
        if(!this.active_company){
            const user_companies = await getCompanies()
            if(user_companies['data'].length){
                this.active_company = user_companies['data'][0].id
            }
        }
        return this.active_company
    },

    logout(){
        auth.logout()
        this.name = ''
        this.user_id = ''
    }
})