<template>
  <BaseLayout>
    <div class="card card-fullscreen">
      <div class="card-body">
        <div class="row gy-sm-1 mb-2">
          <div class="col-md-2">
            <label for="group_by" class="form-label">Группировка</label>
            <select name="group_by" id="group_by" class="form-control border-1" v-model="searchData.group_by"
                    @change="makeSearch">
              <option v-for="(label, key) in groupOptions" :key="key" :value="key">
                {{ label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row gy-sm-1">
          <div class="col-md-3">
            <input type="text" class="form-control border-1 py-2" placeholder="Поиск по названию"
                   v-model="searchData.name" @change="makeSearch">
          </div>
          <div class="col-md-3">
            <select class="form-select" v-model="searchData.stores" multiple @change="makeSearch">
              <template v-for="(store, index) in stores" :key="index">
                <option :value="store.id">{{ store.name }}</option>
              </template>
            </select>
          </div>
        </div>

      </div>
      <div style="height: 100%">

      </div>
    </div>
    <template v-if="reportTotals">
      <div class="row">
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Сумма</h5>
              <p class="card-subtitle">оформленных заказов</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['total_sum']) }} <small>{{ currency }}</small></h4>
                <small class="text-success"></small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Количество</h5>
              <p class="card-subtitle">заказанных товаров</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['products_count']) }} <small>шт</small></h4>
                  <small class="text-end">{{ formatNumber(reportTotals['average_price']) }} {{ currency }}<br>средний чек</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Себестоимость</h5>
              <p class="card-subtitle">заказанных товаров</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['total_cost']) }} <small>{{ currency }}</small></h4>
                <small class="text-success"></small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Комиссия</h5>
              <p class="card-subtitle">прогноз</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['total_commission']) }} <small>{{ currency }}</small></h4>
                <small class="text-success"></small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Налоги</h5>
              <p class="card-subtitle">предварительно</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['total_tax']) }} <small>{{ currency }}</small></h4>
                <small class="text-success"></small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-4 col-6 mt-6">
          <div class="card h-100">
            <div class="card-header pb-3">
              <h5 class="card-title mb-1">Прибыль</h5>
              <p class="card-subtitle">прогноз</p>
              <div class="d-flex justify-content-between align-items-center gap-3">
                <h4 class="mb-0">{{ formatNumber(reportTotals['total_profit']) }} <small>{{ currency }}</small><small class="text-end"> ({{ formatNumber(reportTotals['profit_percent'], 2) }} %)</small></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="card card-fullscreen mt-6">
      <h5 class="card-header">Сводный отчет</h5>
      <div class="card-body">
        <div class="table-responsive text-nowrap">
          <table class="table table-bordered table-hover">
            <thead>
            <tr>
              <th>{{ groupOptions[searchData.group_by] }}</th>
              <th style="text-align: right">Заказано товаров</th>
              <th style="text-align: right">Средний чек</th>
              <th style="text-align: right">Сумма</th>
              <th style="text-align: right">Себестоимость</th>
              <th style="text-align: right">Комиссия</th>
              <th style="text-align: right">Налоги</th>
              <th style="text-align: right">Прибыль</th>
              <th style="text-align: right">Процент прибыли</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(row, index) in reportRows" :key="index">
              <tr>
                <td>
                  {{ row.group }}
                </td>
                <td style="text-align: right">
                  {{ row.products_count }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.average_price) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_sum) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_cost) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_commission) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_tax) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_profit, 2) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.profit_percent, 2) }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="card card-fullscreen mt-6">
      <h5 class="card-header">Заказы</h5>
      <div class="card-body">
        <div class="table-responsive text-nowrap">
          <table class="table table-bordered table-hover">
            <thead>
            <tr>
              <th>Номер</th>
              <th>Товар</th>
              <th></th>
              <th style="text-align: right">Сумма</th>
              <th style="text-align: right">Себестоимость</th>
              <th style="text-align: right">Комиссия</th>
              <th style="text-align: right">Налоги</th>
              <th style="text-align: right">Прибыль</th>
              <th style="text-align: right">Процент прибыли</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(row, index) in orders" :key="index">
              <tr>
                <td>
                  {{ row.marketplace_number }}
                </td>
                <td>
                  {{ row.product_current_name }}
                </td>
                <td style="text-align: right">
                  {{ row.quantity }} шт
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_sum) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_cost) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_commission) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_tax) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.total_profit, 2) }}
                </td>
                <td style="text-align: right">
                  {{ formatNumber(row.profit_percent, 2) }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
        </BasePagination>
        </div>
      </div>
    </div>


  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {stores} from "@/data/store";
import BasePagination from "@/components/list/Pagination.vue"
import MarketplaceTooltip from "@/components/tooltips/Marketplace.vue"

import {ReportGroupOptions} from "@/data/orders.js"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {AgGridVue} from "ag-grid-vue3";
import {getOrdersCalculated, getReportV1} from "@/api/order";


export default {
  name: 'ReportV1View',
  // eslint-disable-next-line vue/no-unused-components
  components: {BaseLayout, BasePagination, AgGridVue, MarketplaceTooltip},
  data() {
    return {
      currency: 'р.',
      groupOptions: ReportGroupOptions,
      stores: [],
      paginationInfo: {
        page: 1,
        size: 50
      },
      searchData: {
        company: '',
        name: '',
        code: '',
        brand: '',
        creation_date_from: '',
        creation_date_to: '',
        types: [],
        stores: [],
        statuses: [],
        marketplaces: [],
        group_by: 'day'
      },
      reportRows: [],
      reportTotals: {},
      orders:  [],
    }
  },
  methods: {
    ReportGroupOptions() {
      return ReportGroupOptions
    },
    setPageInfo() {
      PageInfo.title = 'Отчет по заказам'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    setSearch() {
      this.searchData.company = user.active_company
    },
    async makeSearch(){
      await this.makeReport()
      await this.searchOrders()
    },
    async makeReport() {
      let result = await getReportV1(this.searchData)
      this.reportRows = result.data.rows
      this.reportTotals = result.data.totals
    },
    async searchOrders() {
      let result = await getOrdersCalculated(this.searchData, this.paginationInfo.page, this.paginationInfo.size)
      this.orders = result.data.items
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
    },
    formatNumber(value, to_fixed=0) {
      if (!value) return '0';
      value = value.toFixed(to_fixed)
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },
    async changePage(page) {
      this.paginationInfo.page = page
      await this.searchOrders()
    },
  },
  async mounted() {
    this.setPageInfo()
    await user.getUserBase()
    this.setSearch()
    this.stores = await stores.getStores()
    await this.makeSearch()
  },
  watch: {
    '$route'() {
      this.setPageInfo()
      this.setSearch()
    }
  },
}
</script>