<template>
  <div class="modal fade" id="largeModal" tabindex="-1" :class="modalShow ? 'show' : ''">
    <div class="modal-dialog modal-dialog-centered" role="document" v-click-outside="closeModal">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel3">{{ modalTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade" :class="modalShow ? 'show' : ''"></div>
</template>
<script>
import { VOnClickOutside as ClickOutside } from "@/helpers/directives/clickOutside"

export default {
  name: "BaseModal",
  data() {
    return {
      modalShow: false
    }
  },
  props: {
    modalTitle: {
      type: String
    }
  },
  methods: {
    closeModal() {
      if(this.modalShow){
        this.modalShow = false
      }
    },
    openModal(){
      setTimeout(() => this.modalShow = true, 100)
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style scoped>
.modal.show{
  display: block;
}
.modal-backdrop{
  display: none;
}
.modal-backdrop.show{
  display: block;
}
</style>