<template>
  <template v-if="items.length">
    <nav v-if="items.length > 1" aria-label="Page navigation" class="d-flex align-items-center justify-content-center mt-3">
      <ul class="pagination pagination-rounded">
        <li class="page-item first" v-if="first">
          <a class="page-link waves-effect" href="javascript:void(0);" @click="changePage(first)"><i class="ti ti-chevrons-left ti-sm"></i></a>
        </li>
        <li class="page-item prev" v-if="previous">
          <a class="page-link waves-effect" href="javascript:void(0);" @click="changePage(previous)"><i class="ti ti-chevron-left ti-sm"></i></a>
        </li>
        <li class="page-item" v-for="(item, index) in items" :key="index" :class="item.active ? 'active' : ''">
          <a class="page-link waves-effect" href="javascript:void(0);" @click="changePage(item.page)">
            {{ item.page }}
          </a>

        </li>
        <li class="page-item next" v-if="next">
          <a class="page-link waves-effect" href="javascript:void(0);" @click="changePage(next)"><i class="ti ti-chevron-right ti-sm"></i></a>
        </li>
        <li class="page-item last" v-if="last">
          <a class="page-link waves-effect" href="javascript:void(0);" @click="changePage(last)"><i class="ti ti-chevrons-right ti-sm"></i></a>
        </li>
      </ul>
    </nav>
    <div class="d-flex align-items-center justify-content-center mb-3" :class="this.items.length > 1 ? '' : 'mt-3'">
      Всего: {{ total }}
    </div>
  </template>
</template>
<script>

export default {
  name: "BasePagination",
  data() {
    return {
      next: {},
      previous: {},
      last: {},
      first: {},
      items: [],
      total: 0,
      current: ''
    }
  },
  props: {
    info: {
      type: Object
    }
  },
  methods: {
    setInfo(info) {
      this.total = info.total
      this.page = info.page
      let start = info.page - 3
      let end = info.page + 3
      if (start <= 0) {
        start = 1
      }
      if (end > info.pages) {
        end = info.pages
      }
      let next = start
      let active = false
      this.items = []
      while (next <= end) {
        active = next === info.page;
        this.items.push(
            {
              'page': next,
              'active': active
            }
        )
        next++
      }
      if(info.pages > 1 && this.page !== 1){
        this.first = 1
      }else{
        this.first = ''
      }
      if(info.pages > this.page){
        this.last = info.pages
        this.next = this.page + 1
      }else{
        this.last = ''
        this.next = ''
      }
      if(this.page > 1){
        this.previous = this.page - 1
      }else{
        this.previous = ''
      }

    },
    paginate() {

    },
    changePage(page){
      this.$emit('changePage', page)
    }
  },
  watch: {
    info(new_info) {
      this.setInfo(new_info)
    }
  },
  emits: ['changePage']
}
</script>