<template>
  <form @submit.prevent="yandexSubmit">
    <div class="col mb-4">
      <label class="form-label">Название магазина</label>
      <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
    </div>
    <input type="hidden" name="company" v-model="item_data.company">
    <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
    <div class="col mb-4">
      <label class="form-label">ID кабинета (Business ID)</label>
      <input type="text" name="connection[business_id]" class="form-control" placeholder="" required
             v-model="item_data.connection.business_id">
    </div>
    <div class="col mb-4">
      <label class="form-label">ID магазина (Campaing ID)</label>
      <input type="text" name="connection[campaign_id]" class="form-control" placeholder="" required
             v-model="item_data.connection.campaign_id">
    </div>
    <div class="col mb-4">
      <label class="form-label">API Key</label>
      <input type="text" name="connection[api_key]" class="form-control" placeholder="" required
             v-model="item_data.connection.api_key">
    </div>
    <div class="col mb-4">
      <label class="form-label">Модель работы</label>
      <select class="form-control" name="settings[model]" v-model="item_data.settings.model">
        <option value="fbs">FBS</option>
      </select>
    </div>
    <div class="col mb-4">
      <label class="form-label">Фиксированная комиссия (например, обработка отправления в ПВЗ), руб.</label>
      <input type="text" name="settings[fixed_commission]" class="form-control" placeholder=""
             v-model="item_data.settings.fixed_commission">
    </div>
    <div class="col mb-4">
      <label class="form-label">Дополнительная комиссия (реклама, буст продаж и т.п.), %</label>
      <input type="text" name="settings[percent_commission]" class="form-control" placeholder=""
             v-model="item_data.settings.percent_commission">
    </div>
    <div class="col mb-4">
      <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
    </div>
  </form>
</template>
<script>
import {user} from "@/data/user";
import {editStoreYandex, newStoreYandex} from "@/api/store";

export default {
  name: "YandexForm",
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'yandex',
        connection: {
          business_id: '',
          campaign_id: '',
          api_key: ''
        },
        settings: {
          model: 'fbs',
          fixed_commission: 10,
          percent_commission: 0
        }
      }
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
  },
  methods: {
    async yandexSubmit() {
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        const result = await newStoreYandex(this.item_data)
        console.log(result)
      } else {
        const result = await editStoreYandex(this.item_data)
        console.log(result)
      }
    }
  }
}
</script>