<template>
  <form @submit.prevent="importSubmit">
    <div class="col mb-4">
      <p v-if="type === 'costs'">
        {{ update_costs.text }}
      </p>
      <p v-if="type === 'minPrices'">
        {{ update_min_prices.text }}
      </p>
    </div>
    <div class="col mb-4">
      <label class="form-label">Загрузите файл</label>
      <input type="file" name="file" class="form-control" placeholder="" required @change="onFileChange">
    </div>
    <div class="col mb-4 text-center">
      <input type="submit" class="btn btn-primary" value="Отправить">
    </div>
  </form>
</template>
<script>
import {user} from "@/data/user";
import {uploadCosts, uploadMinPrices} from "@/api/product";


export default {
  name: "ProductImportFileForm",
  data() {
    return {
      update_costs: {
        'text': 'Загрузите Excel файл с 2 колонками. Первую назовите "код товара", вторую - "себестоимость"',
      },
      update_min_prices: {
        'text': 'Загрузите Excel файл с 2 колонками. Первую назовите "код товара", вторую - "ррц"',
      },
      file: null,
      type: null
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async importSubmit() {
      const company = await user.getActiveCompany()
      if (this.type === 'costs') {
        await uploadCosts(company, this.file)
      }
      if (this.type === 'minPrices')
        await uploadMinPrices(company, this.file)
    },
    setImport(type) {
      if (type === 'minPrices') {
        this.type = 'minPrices'
      }
      if (type === 'costs') {
        this.type = 'costs'
      }
    }
  },
}
</script>