<template>
  <RouterView />
</template>

<script>
export default {
  name: 'App',
}
</script>


<style src="./assets/vuexy/vendor/css/rtl/core.css"></style>
<style src="./assets/vuexy/vendor/css/rtl/theme-default.css"></style>
<style src="./assets/vuexy/vendor/fonts/tabler-icons.css"></style>
<style src="./assets/vuexy/css/demo.css"></style>
