<template>
  <form @submit.prevent="productSubmit">
    <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
    <div class="col mb-4">
      <label class="form-label">Название товара</label>
      <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
    </div>
    <input type="hidden" name="company" class="form-control" placeholder="" required v-model="item_data.company">
    <div class="col mb-4">
      <label class="form-label">Внутренний код товара (артикул продавца)</label>
      <input type="text" name="code" class="form-control" placeholder="" required v-model="item_data.code">
    </div>
    <div class="col mb-4">
      <label class="form-label">Себестоимость</label>
      <input type="text" name="cost" class="form-control" placeholder="" v-model="item_data.cost">
    </div>
    <div class="col mb-4">
      <label class="form-label">Минимальная цена (РРЦ)</label>
      <input type="text" name="min_price" class="form-control" placeholder="" v-model="item_data.min_price">
    </div>
    <div class="col mb-4">
      <label class="form-label">Бренд</label>
      <input type="text" name="brand" class="form-control" placeholder="" v-model="item_data.brand">
    </div>
    <div class="col mb-4">
      <label class="form-label">Ширина (мм)</label>
      <input type="text" name="width_mm" class="form-control" placeholder="" v-model="item_data.width_mm">
    </div>
    <div class="col mb-4">
      <label class="form-label">Высота (мм)</label>
      <input type="text" name="height_mmm" class="form-control" placeholder="" v-model="item_data.height_mm">
    </div>
    <div class="col mb-4">
      <label class="form-label">Длина (мм)</label>
      <input type="text" name="length_mm" class="form-control" placeholder="" v-model="item_data.length_mm">
    </div>
    <div class="col mb-4">
      <label class="form-label">Вес (г)</label>
      <input type="text" name="weight_gr" class="form-control" placeholder="" v-model="item_data.weight_gr">
    </div>
    <div class="col mb-4 text-center">
      <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
    </div>
  </form>
</template>
<script>
import {user} from "@/data/user";
import {newProduct, updateProduct} from "@/api/product";


export default {
  name: "ProductForm",
  data() {
    return {
      item_data: {
        id: '',
        company: '',
        code: '',
        name: '',
        brand: '',
        width_mm: '',
        height_mm: '',
        length_mm: '',
        weight_gr: '',
        cost: '',
        min_price: ''
      }
    }
  },
  methods: {
    async productSubmit() {
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        const result = await newProduct(this.item_data)
        console.log(result)
      } else {

        const result = await updateProduct(this.item_data.id, this.item_data)
        console.log(result)
      }
    },
    updateItem(item){
      this.item_data = item
    }
  }
}
</script>