<template>
  <nav aria-label="breadcrumb" v-if="PageInfo.breadcrumbs">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, index) in PageInfo.breadcrumbs" :key="index">
        <RouterLink v-if="item.link" :to="item.link">{{ item.title }}</RouterLink>
        <span v-if="!item.link" style="color:gray">{{item.title}}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import {PageInfo} from "@/data/page";

export default {
  name: "BreadCrumb",
  computed: {
    PageInfo() {
      return PageInfo
    }
  }
}
</script>