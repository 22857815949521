<template>
  <BaseLayout>
    {{ user_name }}
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/Base.vue";
import {user} from "@/data/user";

export default {
  name: 'HomeView',
  components: {BaseLayout},
  data() {
    return {
      user_name: ''
    }
  },
  methods: {
    async getUserInfo() {
      const result = await user.getUserBase()
      this.user_name = result.name
    }
  },
  beforeMount() {
    this.getUserInfo()
  }
}
</script>