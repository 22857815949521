import { reactive } from 'vue'

export const menu = reactive({
    items: [
        {
            title: 'Главная',
            to: '/',
            icon: 'ti-home-2',
            name: 'home',
            active: false
        },
        {
            title: 'Ценообразование',
            to: '/prices',
            icon: 'ti-list',
            name: 'prices',
            active: false
        },
        {
            title: 'Заказы',
            to: '/report_v1',
            icon: 'ti-table',
            name: 'report_v1',
            active: false
        },
        {
            title: 'Товары',
            to: '/products',
            icon: 'ti-box',
            name: 'products',
            active: false
        },
        {
            title: 'Магазины',
            to: '/stores',
            icon: 'ti-brand-appgallery',
            name: 'stores',
            active: false
        },
    ],
    update(to){
        const active = to.name
        this.items.filter((item) => item.active).forEach((item) => item.active = false)
        this.items.filter((item) => item.name === active).forEach((item) => item.active = true)
        if(MobileMenu.open){
            MobileMenu.toggle()
        }
    }
})

export const MobileMenu = reactive({
    open: false,

    toggle(){
        this.open = !this.open
    }
})