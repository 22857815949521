import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getToken(username, password){
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    return await axios.post(`${API_URL}/v1/auth/token`, params)
}

export async function getUser(user_id){
    return await axios.get(
        `${API_URL}/v1/user/${user_id}/info`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}