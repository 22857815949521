import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getProducts(company_id, query = '') {
    query = new URLSearchParams(query).toString()
    return await axios.get(
        `${API_URL}/v1/product/list?company=${company_id}&${query}`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function deleteProduct(product_id) {
    return await axios.delete(
        `${API_URL}/v1/product/${product_id}/delete`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function updateProduct(product_id, data) {
    return await axios.put(
        `${API_URL}/v1/product/${product_id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function newProduct(data) {
    return await axios.post(
        `${API_URL}/v1/product/create`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function uploadCosts(company_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(
        `${API_URL}/v1/product/costs/update-by-excel?company_id=${company_id}`,
        formData,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`, 'Content-Type': 'multipart/form-data'}},
    )
}

export async function uploadMinPrices(company_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(
        `${API_URL}/v1/product/min-price/update-by-excel?company_id=${company_id}`,
        formData,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`, 'Content-Type': 'multipart/form-data'}},
    )
}

export async function updateCosts(company_id, costs) {
    return await axios.post(
        `${API_URL}/v1/product/costs/update-by-code?company_id=${company_id}`,
        costs,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function updateMinPrices(company_id, minPrices) {
    return await axios.post(
        `${API_URL}/v1/product/min-price/update-by-code?company_id=${company_id}`,
        minPrices,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}