import { reactive } from 'vue'

export const PageInfo = reactive({
    title: '',
    breadcrumbs: [],
    buttons: [],

    clear(){
        this.title = ''
        this.breadcrumbs = []
        this.buttons = []
    }
})