<template>
  <AuthLayout>
    <!-- Login -->
        <div class="card">
          <div class="card-body">
            <!-- Logo -->
            <div class="app-brand justify-content-center mb-6">
            </div>
            <!-- /Logo -->
            <h4 class="mb-1">Добро пожаловать в Kindata.Profit! 👋</h4>
            <p class="mb-6">Авторизуйтесь или создайте аккаунт для начала работы</p>
            <p v-if="error" class="error">{{ error }}</p>

            <form id="formAuthentication" class="mb-4" @submit.prevent="login">
              <div class="mb-6">
                <label for="email" class="form-label">Email</label>
                <input
                    v-model="username"
                    type="text"
                    class="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Электронная почта"
                    autofocus/>
              </div>
              <div class="mb-6 form-password-toggle">
                <label class="form-label" for="password">Пароль</label>
                <div class="input-group input-group-merge">
                  <input
                      v-model="password"
                      type="password"
                      id="password"
                      class="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"/>
                  <span class="input-group-text cursor-pointer"><i class="ti ti-eye-off"></i></span>
                </div>
              </div>
              <div class="my-8">
                <div class="d-flex justify-content-between">
                  <a href="auth-forgot-password-basic.html">
                    <p class="mb-0">Забыли пароль?</p>
                  </a>
                </div>
              </div>
              <div class="mb-6">
                <button class="btn btn-primary d-grid w-100" type="submit">Войти</button>
              </div>
            </form>

            <p class="text-center">
              <span>Не зарегистрированы? </span>
              <RouterLink to="/register">Создать аккаунт</RouterLink>
            </p>

          </div>
        </div>
        <!-- /Register -->
  </AuthLayout>
</template>

<script>
import { getToken } from "@/api/user";
import { auth } from "@/data/auth";
import router from "@/router";
import AuthLayout from "@/layouts/Auth.vue";

export default {
  name: 'LoginView',
  components: {AuthLayout},
  data() {
    return {
      username: '',
      password: '',
      error: ''
    }
  },
  methods: {
    async login() {
      console.log(auth)
      try{
        const result = await getToken(this.username, this.password)
        this.error = ''
        auth.setToken(result.data.access_token, result.data.refresh_token)
        await router.push({name: 'home'})
      } catch (er){
        this.error = er.response.data.detail
      }

    }
  }
}
</script>