import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getStores(company_id, page = 1, size = 20){
    return await axios.get(
        `${API_URL}/v1/store/list?company=${company_id}&page=${page}&size=${size}`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function deleteStore(store_id){
    return await axios.delete(
        `${API_URL}/v1/store/${store_id}/delete`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}
export async function importProducts(store_id){
    return await axios.post(
        `${API_URL}/v1/store/${store_id}/task/import-products`,
        {},
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}
export async function getOffers(store_id){
    return await axios.post(
        `${API_URL}/v1/store/${store_id}/task/get-offers`,
        {},
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}
export async function getOrders(store_id){
    return await axios.post(
        `${API_URL}/v1/store/${store_id}/task/get-orders`,
        {},
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}
export async function importStore(store_id){
    return await axios.post(
        `${API_URL}/v1/store/${store_id}/task/import-products`,
        {},
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function getStoreOzone(store_id){
    return await axios.get(
        `${API_URL}/v1/store/ozon/${store_id}/info`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function newStoreOzon(data){
    return await axios.post(
        `${API_URL}/v1/store/ozon/create`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function editStoreOzon(data){
    return await axios.put(
        `${API_URL}/v1/store/ozon/${data.id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}


export async function getStoreWb(store_id){
    return await axios.get(
        `${API_URL}/v1/store/wb/${store_id}/info`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}


export async function newStoreWb(data){
    return await axios.post(
        `${API_URL}/v1/store/wb/create`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function editStoreWb(data){
    return await axios.put(
        `${API_URL}/v1/store/wb/${data.id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}


export async function getStoreYandex(store_id){
    return await axios.get(
        `${API_URL}/v1/store/yandex/${store_id}/info`,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}


export async function newStoreYandex(data){
    return await axios.post(
        `${API_URL}/v1/store/yandex/create`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}

export async function editStoreYandex(data){
    return await axios.put(
        `${API_URL}/v1/store/yandex/${data.id}/update`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}},
    )
}