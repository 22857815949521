import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getOffers(company_id, store_ids, product_ids){
    const data = {
        stores: store_ids,
        products: product_ids
    }
    return await axios.post(
        `${API_URL}/v1/offer/list?company_id=${company_id}`,
        data,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}

export async function setOffers(company_id, offers){
    return await axios.post(
        `${API_URL}/v1/offer/set-offers?company_id=${company_id}`,
        offers,
        {headers: {'Authorization': `Bearer ${auth.getAccessToken()}`}}
    )
}
