<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" :class="MobileMenu.open?'active':''">
    <div class="app-brand demo">
      <a href="index.html" class="app-brand-link">
              <span class="app-brand-logo demo">
                <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                      fill="#7367F0"/>
                  <path
                      opacity="0.06"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                      fill="#161616"/>
                  <path
                      opacity="0.06"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                      fill="#161616"/>
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                      fill="#7367F0"/>
                </svg>
              </span>
        <span class="app-brand-text demo menu-text fw-bold">Vuexy</span>
      </a>

      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto" @click.prevent="MobileMenu.toggle()">
        <i class="ti ti-x d-block d-xl-none ti-md align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1" v-if="menu.items">
      <li class="menu-item" v-for="(item, index) in menu.items" :key="index" :class="item.active?'active':''">
        <RouterLink :to="item.to" v-if="item.to" class="menu-link">
          <i class="menu-icon tf-icons ti" :class="item.icon"></i>
          <div>{{item.title}}</div>
        </RouterLink>
      </li>
    </ul>
  </aside>
</template>

<script>

import {menu, MobileMenu} from "@/data/menu";

export default {
  name: "MainMenu",
  data() {
    return {
      mobileActive: false
    }
  },
  computed: {
    MobileMenu() {
      return MobileMenu
    },
    menu() {
      return menu
    },

  },
  methods: {
    toggleMenu() {
      MobileMenu.open = false
    }
  }
}
</script>

<style scoped>
.layout-menu{
  transition: all 0.5s;
}
.layout-menu.active{
  transform: translate3d(0, 0, 0) !important;
}
</style>