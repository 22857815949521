<template>
  <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl">
      <div
          class="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
        <div class="text-body">
          Kindata.Profit
        </div>
        <div class="d-none d-lg-inline-block">

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseFooter"
}
</script>