import { reactive } from 'vue'
import {getStores} from "@/api/store";
import {user} from "@/data/user";

export const stores = reactive({
    stores: [],
    activeStores: [],

    async getStores(){
        if(this.stores.length){
            return this.stores
        }
        const active_company = await user.getActiveCompany()
        const result = await getStores(active_company)
        this.stores = result.data.items
        return this.stores
    },
    getActiveStores(){
        if(this.activeStores.length){
            return this.activeStores
        }
        if (localStorage.getItem('active_stores')){
            this.activeStores = JSON.parse(localStorage.getItem('active_stores'))
            return this.activeStores
        }
        return this.activeStores
    },
    setActiveStores(stores){
        this.activeStores = stores
        localStorage.setItem('active_stores', JSON.stringify(stores))
    }

})