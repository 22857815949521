import { reactive } from 'vue'
import VueJwtDecode from 'vue-jwt-decode';

export const auth = reactive({
    access_token: '',
    refresh_token: '',
    user_id: '',

    setToken(access_token, refresh_token) {
        this.access_token = access_token
        this.refresh_token = refresh_token
        this.user_id = VueJwtDecode.decode(this.access_token)['sub']

        localStorage.setItem('access_token', this.access_token)
        localStorage.setItem('refresh_token', this.refresh_token)
        localStorage.setItem('user_id', this.user_id)
    },

    getAccessToken(){
        if(this.access_token){
            return this.access_token
        }
        if(localStorage.getItem('access_token')){
            return localStorage.getItem('access_token')
        }
    },

    logout(){
        this.access_token = ''
        this.refresh_token = ''
        this.user_id = ''

        localStorage.setItem('access_token', '')
        localStorage.setItem('refresh_token', '')
        localStorage.setItem('user_id', '')
    },

    checkAuth(){
        return !!this.getAccessToken();
    }
})