<template>
  <form @submit.prevent="ozonSubmit">
    <div class="col mb-4">
      <label class="form-label">Название магазина</label>
      <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
    </div>
    <input type="hidden" name="company" v-model="item_data.company">
    <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
    <div class="col mb-4">
      <label class="form-label">Client ID</label>
      <input type="text" name="connection[client_id]" class="form-control" placeholder="" required
             v-model="item_data.connection.client_id">
    </div>
    <div class="col mb-4">
      <label class="form-label">API Key</label>
      <input type="text" name="connection[api_key]" class="form-control" placeholder="" required
             v-model="item_data.connection.api_key">
    </div>
    <div class="col mb-4">
      <label class="form-label">Модель работы</label>
      <select class="form-control" name="settings[model]" v-model="item_data.settings.model">
        <option value="fbo">FBO</option>
        <option value="fbs">FBS</option>
      </select>
    </div>
    <div class="col mb-4">
      <label class="form-label">Фиксированная комиссия (например, обработка отправления в ПВЗ), руб.</label>
      <input type="text" name="settings[fixed_commission]" class="form-control" placeholder=""
             v-model="item_data.settings.fixed_commission">
    </div>
    <div class="col mb-4">
      <label class="form-label">Дополнительная комиссия (реклама, досрочная выплата и т.п.), %</label>
      <input type="text" name="settings[percent_commission]" class="form-control" placeholder=""
             v-model="item_data.settings.percent_commission">
    </div>
    <div class="col mb-4">
      <label class="form-label">Коэффициент локализации (число от 0.5 до 1.5, зависит от индекса локализации)</label>
      <input type="text" name="settings[local_coefficient]" class="form-control" placeholder=""
             v-model="item_data.settings.local_coefficient">
    </div>
    <div class="col mb-4">
      <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
    </div>
  </form>
</template>
<script>
import {newStoreOzon, editStoreOzon} from "@/api/store";
import {user} from "@/data/user";

export default {
  name: "OzonForm",
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'ozon',
        connection: {
          client_id: '',
          api_key: ''
        },
        settings: {
          model: 'fbs',
          fixed_commission: 30,
          percent_commission: 0,
          local_coefficient: 1
        }
      }
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
  },
  methods: {
    async ozonSubmit() {
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        const result = await newStoreOzon(this.item_data)
        console.log(result)
      } else {

        const result = await editStoreOzon(this.item_data)
        console.log(result)
      }
    }
  }
}
</script>